<template>
  <v-row>
    <shipping-rule-aside
      :active="aside"
      :merchant="merchant"
      :resource="merchant.shipping_rule"
      @changed="$emit('updated')"
    >
    </shipping-rule-aside>

    <v-col cols="8">
      <v-card class="pt-5">
        <v-card-title class="d-flex align-center justify-space-between">
          <span>Shipping Fee Policy</span>
        </v-card-title>

        <v-card-text>
          <p>This fee apply to all new tangible orders created for you.</p>
          <p>You may ignore this section if you don't intent to provide tangible products.</p>
          <p>Fee is calculated based on Merchant's country (<b>{{ merchant.country.name.en }}</b>) in currency (<b>{{ merchant.country.currency }}</b>)</p>
          <v-divider />

          <div class="mt-2">
            <span>Shipping Fee: </span>
            <b>{{ merchant.shipping_rule.shipping_fee | localPrice({ currency: merchant.country.currency }) }}</b>
          </div>

          <div class="mt-2">
            <span class="mr-2">Enable shipping fee waive on certain amount subtotal?</span>
            <b>{{ merchant.shipping_rule.enable_waive_fee ? 'Enabled' : 'Disabled' }}</b>
            <br>
            <small>note: PWC does not have such feature. Use min subtotal >= {{ 9999 | localPrice({ currency: merchant.country.currency }) }} instead.</small>
          </div>

          <div
            class="mt-2"
            :class="{ 'cross-out': !merchant.shipping_rule.enable_waive_fee }"
          >
            <span>Free Shipping if min order subtotal is: </span>
            <b>{{ merchant.shipping_rule.waived_at_subtotal | localPrice({ currency: merchant.country.currency }) }}</b>
          </div>

          <div class="mt-2">
            <span>Enable Pay Shipping Fee At arrival: </span>
            <b>{{ merchant.shipping_rule.enable_pay_on_arrival ? 'Enabled' : 'Disabled' }}</b>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="aside = true">
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="8">
      <v-card class="pt-5">
        <v-card-title class="d-flex align-center justify-space-between">
          <span>Enabled Shipping Method</span>
        </v-card-title>

        <v-card-text>
          <sync-shipping-method-form
            v-if="merchant && ready"
            :selected-ids="shippingMethods.map(c => c.id)"
            :sync-base-path="`/admin/merchants/${merchant.id}/shipping-methods/sync`"
            :sync-able="syncAble"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn @click="confirmSync">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { fetchShippingMethods } from '@/api/merchant/merchant'
import { useNotifySuccess } from '@/composables'
import { localPrice } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import SyncShippingMethodForm from '../../shipping-method-resource/SyncShippingMethodForm.vue'
import ShippingRuleAside from '../../shipping-rule-resource/ShippingRuleAside.vue'

export default {
  components: { ShippingRuleAside, SyncShippingMethodForm },
  filters: { localPrice },

  props: {
    merchant: { type: Object, required: true },
  },

  setup(props) {
    const aside = ref(false)
    const syncAble = ref(false)
    const ready = ref(false)
    const shippingMethods = ref([])

    fetchShippingMethods(props.merchant.id).then(res => {
      ready.value = false
      shippingMethods.value = res.data.data.records
      setTimeout(() => {
        ready.value = true
      })
    })

    const confirmSync = () => {
      syncAble.value = true
      useNotifySuccess({ content: 'Shipping Methods updated' })
      setTimeout(() => fetchShippingMethods(props.merchant.id), 300)
    }

    return {
      aside,
      syncAble,
      shippingMethods,
      ready,
      confirmSync,
    }
  },
}
</script>

<style lang="scss">
.html-content {
  img {
    max-width: 100%;
  }
}
.cross-out {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
